import './loginform.css';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import { FaUserDoctor } from "react-icons/fa6";

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    if (role === 'admin' && username === 'admin' && password === 'password') {
      navigate('/dashboard');
    } else if (role === 'doctor' && username === 'doctor' && password === 'password') {
      navigate('/doctordashboard');
    } else if (role === 'patient' && username === 'patient' && password === 'password') {
      navigate('/patientdashboard');
    } else {
      // Handle invalid credentials
      // You can display an error message on the page or reset the form fields
    }
  };

  return (
    <Container fluid className="login-container">
      <Row className="justify-content-center align-items-center" >
        <Col xs={12} md={6} lg={4}>
          <Card className="login-card text-white">
            <Card.Body>
              <div className="text-center">
                <FaUserDoctor size='70px' className='loginicon'/>
              </div>
              <Card.Title className="text-center text-dark">Login</Card.Title>
              <p className="text-center text-dark">
                Don't have an account? <Link to="/signup" className="text-dark">Sign Up</Link>
              </p>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3 text-dark" controlId="formGrouprole">
                  <Form.Label>Role</Form.Label>
                  <Form.Select 
                    aria-label="Select role" 
                    value={role} 
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Select your role</option>
                    <option value="admin">Admin</option>
                    <option value="doctor">Doctor</option>
                    <option value="patient">Patient</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 text-dark" controlId="formGroupEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter email" 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 position-relative text-dark" controlId="formGroupPassword">
                  <Form.Label>Password</Form.Label>
                  <Link to="/forgot" className="forgot-password-link">Forgot Password?</Link>
                  <Form.Control 
                    type="password" 
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 text-start text-dark">
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 rounded-pill">Login</Button>
                <div className="text-center my-3 divider">or Login with</div>
                <div className="d-flex justify-content-around">
                  <Button variant="outline-dark" className="d-flex align-items-center">
                    <FaGoogle className="me-2" /> Google
                  </Button>
                  <Button variant="outline-dark" className="d-flex align-items-center">
                    <FaFacebook className="me-2" /> Facebook
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
