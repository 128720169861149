import Accordion from 'react-bootstrap/Accordion';
import { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './PatientForm.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Row, Col,Button } from 'react-bootstrap';

function PatientForm() {
  const [activeKey, setActiveKey] = useState(['0', '1', '2']); // Accordion always open

  const handleToggle = (key) => {
    if (activeKey.includes(key)) {
      setActiveKey(activeKey.filter(k => k !== key)); // Close the Accordion item
    } else {
      setActiveKey([...activeKey, key]); // Open the Accordion item
    }
  };

  return (
    <Container className='container1'>
      <h2 className='mb-4 text-center'>Patient Registration Form</h2>
      <Accordion activeKey={activeKey} onSelect={handleToggle}>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={() => handleToggle('0')}>
            <span className="custom-icon">
              {activeKey.includes('0') ? <FaMinus /> : <FaPlus />}
            </span>
            Search Primary Information
          </Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Group controlId="mobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter mobile number"
                  className="rounded-input"
                />
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={() => handleToggle('1')}>
            <span className="custom-icon">
              {activeKey.includes('1') ? <FaMinus /> : <FaPlus />}
            </span>
            Patient Basic Detail
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col xs={12} md={3}>
                <Form.Group controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="middleName">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter middle name"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="gender">
                  <Form.Label>Select Gender</Form.Label>
                  <Form.Control as="select" className="rounded-input">
                    <option>Choose</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Form.Group controlId="mobileNumber2">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter mobile number"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="email">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email ID"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="age">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter age"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="address">
                  <Form.Label>Patient Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Form.Group controlId="country">
                  <Form.Label>Select Country</Form.Label>
                  <Form.Control as="select" className="rounded-input">
                    <option>Choose</option>
                    <option>India</option>
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Australia</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control as="select" className="rounded-input">
                    <option>Choose</option>
                    <option>India</option>
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Australia</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="city">
                  <Form.Label>City/Village</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group controlId="pincode">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter pincode"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="2">
          <Accordion.Header onClick={() => handleToggle('2')}>
            <span className="custom-icon">
              {activeKey.includes('2') ? <FaMinus /> : <FaPlus />}
            </span>
            Patient Health Detail
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group controlId="height">
                  <Form.Label>Height ( in Feet Inches )</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Height ( in Feet inches )"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="weight">
                  <Form.Label>Weight ( kg )</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Weight ( kg )"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="bp">
                  <Form.Label>Blood Pressure ( mmHg )</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Blood Pressure ( mmHg )"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group controlId="spo2">
                  <Form.Label>SPO2 ( % )</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="SPO2 ( % )"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="temprature">
                  <Form.Label>Temperature ( F )</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Temperature ( F )"
                    className="rounded-input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="diabetes">
                  <Form.Label>Diabetes</Form.Label>
                  <Form.Control as="select" className="rounded-input">
                    <option>Choose</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Add hr tag which is not closed when the 2nd accordion is closed */}
      <hr />

      {/* Two text fields: Allergies and Note */}
      <Row>
        <Col xs={12}>
          <Form.Group controlId="allergies">
            <Form.Label>Allergies</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter allergies"
              className="rounded-input"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group controlId="note">
            <Form.Label>Note</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter any notes"
              rows={3}
              className="rounded-input"
            />
          </Form.Group>
        </Col>
        
      </Row>
      <Button className='mt-4 mb-2' variant="primary">
            Save
          </Button>
    </Container>
  );
}

export default PatientForm;
