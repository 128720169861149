import './doctordashboard.css';
import React, { useState, useEffect } from 'react';
import { CgMenuLeftAlt } from "react-icons/cg";
import { MdLogout, MdDashboard } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const DoctorDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Start closed on mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSidebarItemClick = () => {
    if (isMobile) {
      setSidebarOpen(false); // Close the sidebar on mobile after item click
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, logout',
      cancelButtonText: 'No, stay logged in'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/');
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        setSidebarOpen(true); // Ensure the sidebar is open on desktop view
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="dashboard-container">
      <nav className="navbar">
        <button className="navbar-toggle-btn" onClick={toggleSidebar}>
          <CgMenuLeftAlt size={30} />
        </button>
        <span className="navbar-title">Client Dashboard</span>
      </nav>
      <div className={`sidebar ${sidebarOpen ? 'open' : 'collapsed'} ${isMobile ? 'mobile' : ''}`}>
        <ul className="sidebar-menu">
          {/* Dashboard Button */}
          <li className="sidebar-item">
            <Link to="clienthome" className="sidebar-button" onClick={handleSidebarItemClick}>
              <MdDashboard className="sidebar-icon" />
              {sidebarOpen && <span className="sidebar-text">Dashboard</span>}
            </Link>
          </li>

          {/* Order Details Button */}
          <li className="sidebar-item">
            <Link to="treatment" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaClipboardList className="sidebar-icon" />
              {sidebarOpen && <span className="sidebar-text">Treatment</span>}
            </Link>
          </li>

          {/* Logout Button */}
          <li className="sidebar-item">
            <button className="sidebar-button logout-button" onClick={() => { handleSidebarItemClick(); handleLogout(); }}>
              <MdLogout className="sidebar-icon logout-icon" />
              {sidebarOpen && <span className="sidebar-text">Logout</span>}
            </button>
          </li>
        </ul>
      </div>
      <div className={`content-container ${sidebarOpen ? '' : 'sidebar-closed'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default DoctorDashboard;
