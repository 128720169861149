import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal, InputGroup } from 'react-bootstrap';
import { LuPlusCircle } from "react-icons/lu";
import { AiFillEdit, AiFillDelete, AiFillEye, AiOutlineSearch } from "react-icons/ai";
import { Link } from 'react-router-dom';

export default function Patient() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPatients, setFilteredPatients] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const patientData = [
    { patientName: 'John Doe', contactNo: '1234567890', caseNo: '001', totalTreatment: '5', registrationDate: '2024-01-10' },
    { patientName: 'Jane Smith', contactNo: '0987654321', caseNo: '002', totalTreatment: '3', registrationDate: '2024-02-20' },
  ];

  const handleSearch = () => {
    const filtered = patientData.filter((patient) =>
      patient.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      patient.contactNo.includes(searchQuery)
    );
    setFilteredPatients(filtered);
  };

  // To display either filtered data or all data if no search has been made
  const patientsToDisplay = searchQuery ? filteredPatients : patientData;

  const totalPatients = patientsToDisplay.length;
  const patientsThisMonth = patientsToDisplay.filter(patient => new Date(patient.registrationDate).getMonth() === new Date().getMonth()).length;
  const patientsThisWeek = patientsToDisplay.filter(patient => {
    const registrationDate = new Date(patient.registrationDate);
    const currentDate = new Date();
    const firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
    const lastDayOfWeek = firstDayOfWeek + 6;
    return registrationDate >= new Date(currentDate.setDate(firstDayOfWeek)) && registrationDate <= new Date(currentDate.setDate(lastDayOfWeek));
  }).length;
  const patientsToday = patientsToDisplay.filter(patient => new Date(patient.registrationDate).toDateString() === new Date().toDateString()).length;

  return (
    <Container fluid>
      <Row className="mb-3 align-items-center">
        <Col md={8}>
          <h2>Patient</h2>
        </Col>
        <Col md={4} className="text-end">
          <Button variant="primary" className="me-2" onClick={handleShowModal}>
            Quick Entry <LuPlusCircle size='20px' />
          </Button>
          <Button variant="secondary" as={Link} to='patientform'>
            Add Patient <LuPlusCircle size='20px' />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row className="mb-3 align-items-center">
          <Col md={4}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search by Name or Mobile No"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button variant="outline-secondary" onClick={handleSearch}>
                <AiOutlineSearch />
              </Button>
            </InputGroup>
          </Col>
          <Col md={8} xs={12} className="text-end">
            <div>
              <span className="me-4"><strong>Total:</strong> {totalPatients}</span>
              <span className="me-4"><strong>This Month:</strong> {patientsThisMonth}</span>
              <span className="me-4"><strong>This Week:</strong> {patientsThisWeek}</span>
              <span><strong>Today:</strong> {patientsToday}</span>
            </div>
          </Col>
        </Row>
      </Form>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Contact No</th>
            <th>Case No</th>
            <th>Total Treatment</th>
            <th>Registration Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {patientsToDisplay.map((patient, index) => (
            <tr key={index}>
              <td>{patient.patientName}</td>
              <td>{patient.contactNo}</td>
              <td>{patient.caseNo}</td>
              <td>{patient.totalTreatment}</td>
              <td>{patient.registrationDate}</td>
              <td>
                <Button variant="outline-success" size="sm" className="me-2">
                  <AiFillEye /> 
                </Button>
                <Button variant="outline-primary" size="sm" className="me-2">
                  <AiFillEdit /> 
                </Button>
                <Button variant="outline-danger" size="sm">
                  <AiFillDelete /> 
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Quick Entry */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Quick Entry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Case No</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Total Treatment</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Registration Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
