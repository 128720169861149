import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Input,
} from '@mui/material';

const DocumentSubmission = () => {
  const [files, setFiles] = useState({
    passportPhoto: null,
    resume: null,
    certificates: null,
    medicalLicense: null,
    identityProof: null,
    addressProof: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles({
      ...files,
      [name]: files[0], // Assuming only one file per input
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle file submission here
    console.log('Documents Submitted', files);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom
            sx={{
              textAlign: 'center',  
              color: 'blue',        
            }}
        >
          Documents Submission
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">Attach Passport-size Photo:</Typography>
            <Input
              type="file"
              name="passportPhoto"
              onChange={handleFileChange}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">Upload Resume/CV:</Typography>
            <Input
              type="file"
              name="resume"
              onChange={handleFileChange}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">Attach Copies of Certificates/Degrees:</Typography>
            <Input
              type="file"
              name="certificates"
              onChange={handleFileChange}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">Attach Medical License (if applicable):</Typography>
            <Input
              type="file"
              name="medicalLicense"
              onChange={handleFileChange}
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">Upload Identity Proof (e.g., Passport, Aadhaar Card):</Typography>
            <Input
              type="file"
              name="identityProof"
              onChange={handleFileChange}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">Upload Address Proof:</Typography>
            <Input
              type="file"
              name="addressProof"
              onChange={handleFileChange}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default DocumentSubmission;
