import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal, InputGroup, Dropdown } from 'react-bootstrap';
import { AiFillEdit, AiFillDelete, AiFillEye, AiOutlineSearch } from "react-icons/ai";
import { Link } from 'react-router-dom';

export default function Appointment() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const appointmentData = [
    { patientName: 'John Doe', contactNo: '1234567890', appointmentDate: '2024-08-30', status: 'Requested' },
    { patientName: 'Jane Smith', contactNo: '0987654321', appointmentDate: '2024-08-30', status: 'Confirmed' },
    // Add more appointment data here
  ];

  // Function to handle search query and filter appointments
  const handleSearch = () => {
    const filtered = appointmentData.filter((appointment) =>
      appointment.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      appointment.contactNo.includes(searchQuery)
    );
    setFilteredAppointments(filtered);
  };

  // To display either filtered data or all data if no search has been made
  const appointmentsToDisplay = searchQuery ? filteredAppointments : appointmentData;

  const totalAppointments = appointmentsToDisplay.length;
  const requestedAppointments = appointmentsToDisplay.filter(app => app.status === 'Requested').length;
  const confirmedAppointments = appointmentsToDisplay.filter(app => app.status === 'Confirmed').length;
  const startedAppointments = appointmentsToDisplay.filter(app => app.status === 'Started').length;
  const completedAppointments = appointmentsToDisplay.filter(app => app.status === 'Completed').length;

  return (
    <Container fluid>
      <Row className="mb-3 align-items-center">
        <Col md={8}>
          <h2>Appointments</h2>
        </Col>
        <Col md={4} className="text-end">
          <Button variant="primary" className="me-2" >
            Add Clinic Time
          </Button>
          <Button variant="secondary" onClick={handleShowModal}>
            Add Appointment
          </Button>
        </Col>
      </Row>

      <Form>
        <Row className="mb-3 align-items-center">
          <Col md={4}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search by Name or Mobile No"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button variant="outline-secondary" onClick={handleSearch}>
                <AiOutlineSearch />
              </Button>
            </InputGroup>
          </Col>
          <Col md={8} className="text-end">
            <div>
              <span className="me-4"><strong>Total:</strong> {totalAppointments}</span>
              <span className="me-4"><strong>Requested:</strong> {requestedAppointments}</span>
              <span className="me-4"><strong>Confirmed:</strong> {confirmedAppointments}</span>
              <span className="me-4"><strong>Started:</strong> {startedAppointments}</span>
              <span><strong>Completed:</strong> {completedAppointments}</span>
            </div>
          </Col>
        </Row>
      </Form>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Contact No</th>
            <th>Appointment Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {appointmentsToDisplay.map((appointment, index) => (
            <tr key={index}>
              <td>{appointment.patientName}</td>
              <td>{appointment.contactNo}</td>
              <td>{appointment.appointmentDate}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-status">
                    {appointment.status}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                      appointment.status = 'Requested';
                      setFilteredAppointments([...filteredAppointments]);
                    }}>Requested</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      appointment.status = 'Confirmed';
                      setFilteredAppointments([...filteredAppointments]);
                    }}>Confirmed</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      appointment.status = 'Started';
                      setFilteredAppointments([...filteredAppointments]);
                    }}>Started</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      appointment.status = 'Completed';
                      setFilteredAppointments([...filteredAppointments]);
                    }}>Completed</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>
                <Button variant="outline-success" size="sm" className="me-2">
                  <AiFillEye /> 
                </Button>
                <Button variant="outline-primary" size="sm" className="me-2">
                  <AiFillEdit /> 
                </Button>
                <Button variant="outline-danger" size="sm">
                  <AiFillDelete /> 
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Quick Entry */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Book an Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Patient Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Contact No</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Appointment Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select">
                <option>Requested</option>
                <option>Confirmed</option>
                <option>Started</option>
                <option>Completed</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
