import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const JobDetails = () => {
  const [formValues, setFormValues] = useState({
    jobTitle: '',
    department: '',
    employmentType: '',
    dateOfJoining: '',
    supervisor: '',
    shiftInformation: '',
    roomOrWorkstationNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Position & Department Form Submitted', formValues);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom 
              sx={{
                textAlign: 'center',  
                color: 'blue',        
              }}
        >
          Position & Department Information
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Job Title"
            name="jobTitle"
            value={formValues.jobTitle}
            onChange={handleChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Department</InputLabel>
            <Select
              name="department"
              value={formValues.department}
              onChange={handleChange}
            >
              <MenuItem value="Nursing">Nursing</MenuItem>
              <MenuItem value="Radiology">Radiology</MenuItem>
              <MenuItem value="Surgery">Surgery</MenuItem>
              <MenuItem value="Administration">Administration</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Employment Type</InputLabel>
            <Select
              name="employmentType"
              value={formValues.employmentType}
              onChange={handleChange}
            >
              <MenuItem value="Full-time">Full-time</MenuItem>
              <MenuItem value="Part-time">Part-time</MenuItem>
              <MenuItem value="Contract">Contract</MenuItem>
              <MenuItem value="Intern">Intern</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            type="date"
            label="Date of Joining"
            name="dateOfJoining"
            value={formValues.dateOfJoining}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            fullWidth
            label="Supervisor/Manager"
            name="supervisor"
            value={formValues.supervisor}
            onChange={handleChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Shift Information</InputLabel>
            <Select
              name="shiftInformation"
              value={formValues.shiftInformation}
              onChange={handleChange}
            >
              <MenuItem value="Day">Day</MenuItem>
              <MenuItem value="Night">Night</MenuItem>
              <MenuItem value="Rotating">Rotating</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Room or Workstation Number"
            name="roomOrWorkstationNumber"
            value={formValues.roomOrWorkstationNumber}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default JobDetails;
