import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';

export default function Medicine() {
  const [medicineDetails, setMedicineDetails] = useState({
    medicineName: '',
    dosage: '',
    quantity: '',
    instructions: '',
    manufacturer: '',
    expiryDate: '',
    typeOfMedicine: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMedicineDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to API)
    console.log('Medicine Details:', medicineDetails);
  };

  return (
    <Card className="shadow">
      <Card.Title className="text-center mt-3">Medicine Details</Card.Title>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupMedicineName">
                <Form.Label>Medicine Name</Form.Label>
                <Form.Control
                  type="text"
                  name="medicineName"
                  value={medicineDetails.medicineName}
                  onChange={handleChange}
                  placeholder="Enter medicine name"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupDosage">
                <Form.Label>Dosage</Form.Label>
                <Form.Control
                  type="text"
                  name="dosage"
                  value={medicineDetails.dosage}
                  onChange={handleChange}
                  placeholder="Enter dosage (e.g., 500mg)"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupQuantity">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  value={medicineDetails.quantity}
                  onChange={handleChange}
                  placeholder="Enter quantity"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupTypeOfMedicine">
                <Form.Label>Type of Medicine</Form.Label>
                <Form.Select
                  name="typeOfMedicine"
                  value={medicineDetails.typeOfMedicine}
                  onChange={handleChange}
                  required
                >
                  <option>Choose...</option>
                  <option value="tablet">Tablet</option>
                  <option value="syrup">Syrup</option>
                  <option value="cream">Cream</option>
                  <option value="injection">Injection</option>
                  <option value="injection">Gel</option>
                  <option value="injection">Capsule</option>
                  <option value="injection">Drop</option>
                  <option value="injection">Powder</option>
                  <option value="injection">Lotion</option>
                  <option value="injection">Spray</option>
                  <option value="injection">Suspension</option>
                  <option value="injection">Ointment</option>
                  <option value="injection">Inhaler</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupManufacturer">
                <Form.Label>Manufacturer</Form.Label>
                <Form.Control
                  type="text"
                  name="manufacturer"
                  value={medicineDetails.manufacturer}
                  onChange={handleChange}
                  placeholder="Enter manufacturer"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupExpiryDate">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control
                  type="date"
                  name="expiryDate"
                  value={medicineDetails.expiryDate}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formGroupInstructions">
            <Form.Label>Instructions</Form.Label>
            <Form.Control
              as="textarea"
              name="instructions"
              value={medicineDetails.instructions}
              onChange={handleChange}
              placeholder="Enter instructions (e.g., Take after meals)"
              rows={3}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Save Medicine
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
