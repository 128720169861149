import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2>Contact Us</h2>
          <p>HMS</p>
          <p>Pune, Maharashtra, 411023</p>
          <p><a className='footerlink' href="mailto:info@loginrich.edu.in">Email: info@loginrich.edu</a></p>
          <p><a className='footerlink' href="tel:+919890089721">Phone: 9890089721</a></p>
        </div>
        <div className="footer-section quick-links">
          <h2>Quick Links</h2>
          <ul className="footer-list">
            <li><a href="/aboutus" className="footer-link">About Us</a></li>
            <li><a href="/doctor" className="footer-link">Doctor</a></li>
            <li><a href="/services" className="footer-link">Services</a></li>
            <li><a href="/enquiryform" className="footer-link">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h2 className='footerhead'>Follow Us</h2>
          <div className="footer-icon-container">
            <a href="https://facebook.com" className="footer-icon"><FaFacebookF /></a>
            <a href="https://twitter.com" className="footer-icon"><FaTwitter /></a>
            <a href="https://instagram.com" className="footer-icon"><FaInstagram /></a>
            <a href="https://linkedin.com" className="footer-icon"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="footer-copyright">
        <p>&copy; {new Date().getFullYear()} Hospital Management System. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
