import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import Navigation from './component/Navigation';
import Dashboard from './component/Dashboard';
import LoginForm from './component/LoginForm';
import ForgotForm from './component/ForgotForm';
import Treatment from './component/Treatment';
import PatientForm from './component/PatientForm';
import AddTreatment from './component/AddTreatment';
import ComplaintForm from './component/setting/ComplaintForm';
import Appointment from './component/Appointment';
import DoctorForm from './component/DoctorForm';
import Patient from './component/Patient';
import Medicine from './component/Medicine';
import Employee from './component/Employee';
import Setting from './component/Setting';
import DashboardForm from './component/DashboardForm';
import Footer from './component/Footer';
import DoctorDashboard from './component/Doctor/DoctorDashboard';

function App() {
  return (
    <div className="App">
      <Router>
        <MainContent />
      </Router>
    </div>
  );
}

function MainContent() {
  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/dashboard');
  const isDoctorDashboard = location.pathname.startsWith('/doctordashboard');

  return (
    <>
      {!isDashboard && !isDoctorDashboard && <Navigation />}
      <Routes>

        <Route path="/login" element={<LoginForm></LoginForm>}></Route>
        <Route path="/forgot" element={<ForgotForm></ForgotForm>}></Route>
        
        {/* Admin Dashboard */}
        <Route path="/dashboard/*" element={<Dashboard />} >
        <Route index />
        <Route path="dashboardform" element={<DashboardForm></DashboardForm>} />
        <Route path="patient" element={<Patient></Patient>} />
        <Route path="patient/patientform" element={<PatientForm></PatientForm>} />
        <Route path="treatment" element={<Treatment></Treatment>} />
        <Route path="medicines" element={<Medicine></Medicine>} />
        <Route path="employee" element={<Employee></Employee>} />
        <Route path="complaint" element={<ComplaintForm></ComplaintForm>} />
        <Route path="setting" element={<Setting></Setting>} />
        <Route path="appointments" element={<Appointment></Appointment>} />
        <Route path="doctors" element={<DoctorForm></DoctorForm>} />
        <Route path="treatment/addtreatment" element={<AddTreatment></AddTreatment>} />
        </Route>
       {/* Doctor Dashboard */}
       <Route path="/doctordashboard/*" element={<DoctorDashboard></DoctorDashboard>}>
          <Route index/>
        </Route>
      </Routes>
      {!isDashboard && !isDoctorDashboard && <Footer></Footer>}
    </>
  );
}

export default App;
