import React, { useState } from 'react';
import { Form, Button, Card, ListGroup } from 'react-bootstrap';

export default function AddExamination() {
  const [examination, setExamination] = useState('');
  const [examinationsList, setExaminationsList] = useState([]);

  const handleAddExamination = (e) => {
    e.preventDefault();
    if (examination.trim() !== '') {
      setExaminationsList([...examinationsList, examination.trim()]);
      setExamination('');
    }
  };

  return (
    <Card className="shadow p-3">
      <Card.Title className="text-center">Add Examination Details</Card.Title>
      <Card.Body>
        <Form onSubmit={handleAddExamination}>
          <Form.Group controlId="formExamination">
            <Form.Label>Examination Detail</Form.Label>
            <Form.Control
              type="text"
              value={examination}
              onChange={(e) => setExamination(e.target.value)}
              placeholder="Enter examination detail"
              className="rounded-input"
            />
          </Form.Group>
          <Button type="submit" variant="primary" className="mt-3 rounded-pill">
            Add Examination
          </Button>
        </Form>

        <ListGroup className="mt-4">
          {examinationsList.map((examination, index) => (
            <ListGroup.Item key={index}>{examination}</ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}
