import React, { useState } from 'react';
import { Accordion, Button, Container, Row, Col } from 'react-bootstrap';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonalInformation from './Employee/PersonalInformation';
import JobDetails from './Employee/JobDetails';
import ProfessionalDetail from './Employee/ProfessionalDetail';
import EmergencyContact from './Employee/EmergencyContact';
import BankDetails from './Employee/BankDetails';
import DocumentSubmission from './Employee/DocumentSubmissions';
import WorkHistory from './Employee/WorkHistory';
import HealthInformation from './Employee/HealthInformation';
import DigitalSignature from './Employee/DigitalSignature';

const Confirmation = () => (
  <div>
  
  </div>
);

const stepIcons = [
  <PersonIcon />,
  <WorkIcon />,
  <SchoolIcon />,
  <ContactEmergencyIcon />,
  <AccountBalanceIcon />,
  <DescriptionIcon />,
  <HistoryIcon />,
  <HealthAndSafetyIcon />,
  <EditIcon />,
  <CheckCircleIcon />,
];

function getSteps() {
  return [
    'Personal Information',
    'Job Details',
    'Professional Detail',
    'Emergency Contact',
    'Bank Details',
    'Document Submissions',
    'Work History & References',
    'Health Information',
    'Digital Signature',
    'Confirmation',
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <PersonalInformation />;
    case 1:
      return <JobDetails />;
    case 2:
      return <ProfessionalDetail />;
    case 3:
      return <EmergencyContact />;
    case 4:
      return <BankDetails />;
    case 5:
      return <DocumentSubmission />;
    case 6:
      return <WorkHistory />;
    case 7:
      return <HealthInformation />;
    case 8:
      return <DigitalSignature />;
    case 9:
      return <Confirmation />;
    default:
      return 'Unknown step';
  }
}

const Employee = () => {
  const [activeStep, setActiveStep] = useState(null); // null represents no open accordion
  const steps = getSteps();

  const handleAccordionChange = (index) => {
    setActiveStep(activeStep === index ? null : index); // Toggle logic
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === null ? 0 : Math.min(prevActiveStep + 1, steps.length - 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === null ? 0 : Math.max(prevActiveStep - 1, 0)
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Accordion activeKey={activeStep !== null ? activeStep.toString() : null}>
            {steps.map((step, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header onClick={() => handleAccordionChange(index)}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: 8 }}>{stepIcons[index]}</div>
                      <div>{step}</div>
                    </div>
                    <div>
                      {activeStep === index ? <RemoveIcon /> : <AddIcon />}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {getStepContent(index)}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-between mt-3">
          <Button
            variant="secondary"
            disabled={activeStep === null || activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button onClick={() => alert('Finish')}>
              Finish
            </Button>
          ) : (
            <Button onClick={handleNext} disabled={activeStep === null}>
              Next
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Employee;

