import './forgotpassword.css';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';

const ForgotForm = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']); // Assuming a 6-digit OTP
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const handleSendOtp = () => {
   
    setOtpSent(true);
    
  };

  const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto focus to next input box
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleResetPassword = () => {
    // Implement logic to verify OTP and reset the password
    setOtpVerified(true);
  };

  return (
    <Container fluid className="forgot-password-container">
      <Row className="justify-content-center align-items-center" >
        <Col xs={12} md={6} lg={4}>
          <Card className="forgot-password-card text-white">
            <Card.Body>
              <Card.Title className="text-center text-dark">Forgot Password</Card.Title>
              {!otpSent && (
                <Form>
                  <Form.Group className="mb-3 text-dark" controlId="formGroupEmail">
                    <Form.Label>Enter your Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={handleSendOtp} className="w-100">
                    Send OTP
                  </Button>
                </Form>
              )}
              {otpSent && !otpVerified && (
                <Form>
                  <Form.Group className="mb-3 text-dark">
                    <Form.Label>Enter OTP</Form.Label>
                    <div className="d-flex justify-content-between">
                      {otp.map((data, index) => (
                        <Form.Control
                          key={index}
                          id={`otp-input-${index}`}
                          type="text"
                          value={data}
                          onChange={(e) => handleChangeOtp(e, index)}
                          maxLength={1}
                          className="otp-input"
                        />
                      ))}
                    </div>
                  </Form.Group>
                  <Button variant="primary" onClick={handleResetPassword} className="w-100">
                    Reset Password
                  </Button>
                </Form>
              )}
              {otpVerified && (
                <p className="text-center text-dark">
                  Your password has been reset successfully.
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotForm;
