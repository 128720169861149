import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CertificateForm = () => {
  const [certificateId, setCertificateId] = useState('');
  const [certificateName, setCertificateName] = useState('');
  const [certificateContent, setCertificateContent] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log({
      certificateId,
      certificateName,
      certificateContent,
    });
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4, p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom align="center">
        Certificate Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Certificate ID"
            variant="outlined"
            value={certificateId}
            onChange={(e) => setCertificateId(e.target.value)}
            required
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Certificate Name"
            variant="outlined"
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            required
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Certificate Content:
          </Typography>
          <CKEditor
            editor={ClassicEditor}
            data={certificateContent}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCertificateContent(data);
            }}
          />
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CertificateForm;
