import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const WorkHistory = () => {
  const [formValues, setFormValues] = useState({
    previousJobTitle: '',
    previousEmployer: '',
    employerContactDetails: '',
    reference1Name: '',
    reference1ContactInfo: '',
    reference2Name: '',
    reference2ContactInfo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Work History & References Form Submitted', formValues);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom
              sx={{
                textAlign: 'center',  
                color: 'blue',        
              }}
        >
          Work History & References
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Previous Job Title"
            name="previousJobTitle"
            value={formValues.previousJobTitle}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Previous Employer"
            name="previousEmployer"
            value={formValues.previousEmployer}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Employer Contact Details"
            name="employerContactDetails"
            value={formValues.employerContactDetails}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Reference 1 Name"
            name="reference1Name"
            value={formValues.reference1Name}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Reference 1 Contact Information"
            name="reference1ContactInfo"
            value={formValues.reference1ContactInfo}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Reference 2 Name"
            name="reference2Name"
            value={formValues.reference2Name}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Reference 2 Contact Information"
            name="reference2ContactInfo"
            value={formValues.reference2ContactInfo}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default WorkHistory;
