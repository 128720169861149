import './dashboard.css';
import React, { useState, useEffect } from 'react';
import { CgMenuLeftAlt } from "react-icons/cg";
import { FaHome, FaUser, FaCalendarCheck, FaPills, FaStethoscope, FaUsers, FaBoxes, FaChartBar, FaCog, FaHospitalUser } from 'react-icons/fa';
import { MdLogout } from "react-icons/md";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Start closed on mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSidebarItemClick = () => {
    if (isMobile) {
      setSidebarOpen(false); // Close the sidebar on mobile after item click
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, logout',
      cancelButtonText: 'No, stay logged in'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/');
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        setSidebarOpen(true); // Ensure the sidebar is open on desktop view
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="dashboard-container">
      <nav className="navbar">
        <button className="navbar-toggle-btn" onClick={toggleSidebar}>
          <CgMenuLeftAlt size={30} />
        </button>
        <span className="navbar-title">Admin Dashboard</span>
      </nav>
      <div className={`sidebar ${sidebarOpen ? 'open' : 'collapsed'} ${isMobile ? 'mobile' : ''}`}>
        <ul className="sidebar-menu">
          <li className="sidebar-item">
            <Link to="dashboardform" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaHome className="sidebar-icon dashboard-icon" />
              {sidebarOpen && <span className="sidebar-text">Dashboard</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="patient" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaUser className="sidebar-icon patient-icon" />
              {sidebarOpen && <span className="sidebar-text">Patient</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="treatment" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaHospitalUser className="sidebar-icon treatment-icon" />
              {sidebarOpen && <span className="sidebar-text">Treatment</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="appointments" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaCalendarCheck className="sidebar-icon appointments-icon" />
              {sidebarOpen && <span className="sidebar-text">Appointments</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="medicines" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaPills className="sidebar-icon medicines-icon" />
              {sidebarOpen && <span className="sidebar-text">Medicines</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="doctors" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaStethoscope className="sidebar-icon doctors-icon" />
              {sidebarOpen && <span className="sidebar-text">Doctors</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="employee" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaUsers className="sidebar-icon employee-icon" />
              {sidebarOpen && <span className="sidebar-text">Employee</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="stock" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaBoxes className="sidebar-icon stock-icon" />
              {sidebarOpen && <span className="sidebar-text">Stock</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="reports" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaChartBar className="sidebar-icon reports-icon" />
              {sidebarOpen && <span className="sidebar-text">Reports</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="setting" className="sidebar-button" onClick={handleSidebarItemClick}>
              <FaCog className="sidebar-icon settings-icon" />
              {sidebarOpen && <span className="sidebar-text">Settings</span>}
            </Link>
          </li>
          <li className="sidebar-item">
            <button className="sidebar-button logout-button" onClick={() => { handleSidebarItemClick(); handleLogout(); }}>
              <MdLogout className="sidebar-icon logout-icon" />
              {sidebarOpen && <span className="sidebar-text">Logout</span>}
            </button>
          </li>
        </ul>
      </div>
      <div className={`content-container ${sidebarOpen ? '' : 'sidebar-closed'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
