import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const PersonalInformation = () => {
  const [formValues, setFormValues] = useState({
    fullName: '',
    employeeId: '',
    dateOfBirth: '',
    gender: '',
    nationality: '',
    contactNumber: '',
    emailAddress: '',
    permanentAddress: '',
    currentAddress: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form Submitted', formValues);
  };

  return (
    <Card className='shadow' sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom 
              sx={{
                textAlign: 'center',  
                color: 'blue',        
              }}
        >
          Personal Information
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Full Name"
            name="fullName"
            value={formValues.fullName}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Employee ID"
            name="employeeId"
            value={formValues.employeeId}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            type="date"
            label="Date of Birth"
            name="dateOfBirth"
            value={formValues.dateOfBirth}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Gender</InputLabel>
            <Select
              name="gender"
              value={formValues.gender}
              onChange={handleChange}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Nationality"
            name="nationality"
            value={formValues.nationality}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Contact Number"
            name="contactNumber"
            value={formValues.contactNumber}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            type="email"
            label="Email Address"
            name="emailAddress"
            value={formValues.emailAddress}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Permanent Address"
            name="permanentAddress"
            value={formValues.permanentAddress}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Current Address"
            name="currentAddress"
            value={formValues.currentAddress}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default PersonalInformation;
