import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FaPlus, FaMinus, FaTimes } from 'react-icons/fa';
import './addtreatment.css';

const suggestions = [
  { name: 'Headache' },
  { name: 'Fever' },
  { name: 'Viral' },
  { name: 'Cough' },
  { name: 'Cold' }
];

export default function AddTreatment() {
  const [activeKey, setActiveKey] = useState(['0']);
  const [complaint, setComplaint] = useState('');
  const [complaintsList, setComplaintsList] = useState([]);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [prescription, setPrescription] = useState({
    medicineName: '',
    dosage: '',
    medicineType: '',
    duration: '',
    quantity: '',
    meal: '',
    note: ''
  });
  const handlePrescriptionChange = (e) => {
    const { name, value } = e.target;
    setPrescription(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleAddPrescription = () => {
    // Logic to add a new prescription can be implemented here
    console.log("Prescription Added:", prescription);
  };

  const handleToggle = (key) => {
    if (activeKey.includes(key)) {
      setActiveKey(activeKey.filter(k => k !== key));
    } else {
      setActiveKey([...activeKey, key]);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestionsList(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    return inputValue.length === 0 ? [] : suggestions.filter(suggestion =>
      suggestion.name.toLowerCase().includes(inputValue)
    );
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => (
    <div>{suggestion.name}</div>
  );

  const handleAddComplaint = () => {
    if (complaint.trim() !== '' && !complaintsList.includes(complaint)) {
      setComplaintsList([...complaintsList, complaint]);
      setComplaint('');
    }
  };

  const handleRemoveComplaint = (index) => {
    setComplaintsList(complaintsList.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Title className="text-center mt-3">Add Treatment</Card.Title>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formGroupPatientName">
                  <Form.Label>Patient Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter patient name" className="rounded-input" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formGroupTotalFees">
                  <Form.Label>Total Fees</Form.Label>
                  <Form.Control type="number" placeholder="Enter total fees" className="rounded-input" />
                </Form.Group>
              </Col>
            </Row>

            <Row>
            <Col md={6}>
                <Form.Group className="mb-3" controlId="formGroupPaidFees">
                  <Form.Label>Paid Fees</Form.Label>
                  <Form.Control type="number" placeholder="Enter paid fees" className="rounded-input" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formGroupFollowUpDate">
                 <Form.Label>Follow-Up Date</Form.Label>
                  <Form.Control type="date" className="rounded-input" />
                </Form.Group>
              </Col>
            </Row>
            <Accordion defaultActiveKey="0" activeKey={activeKey} onSelect={handleToggle}>
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => handleToggle('0')}>
                  <span className="custom-icon">
                    {activeKey.includes('0') ? <FaMinus /> : <FaPlus />}
                  </span>
                  Complaints
                </Accordion.Header>
                <Accordion.Body>
                  <div className="complaint-form position-relative p-3 border rounded">
                    <Form.Group controlId="formGroupComplaints">
                      <Form.Label>Complaints</Form.Label>
                      <Autosuggest
                        suggestions={suggestionsList}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                          placeholder: 'Enter complaints',
                          className: 'suggestion-box',
                          value: complaint,
                          onChange: (e, { newValue }) => setComplaint(newValue),
                          onBlur: handleAddComplaint // Add complaint on blur (optional)
                        }}
                      />
                    </Form.Group>
                    <Button variant="primary" className="mt-2 rounded-pill" onClick={handleAddComplaint}>
                      Add Complaint
                    </Button>
                    <div className="mt-3">
                      {complaintsList.map((complaint, index) => (
                        <div key={index} className="complaint-item d-flex align-items-center mb-2">
                          <span className="mr-2">{complaint}</span>
                          <FaTimes className="text-danger" style={{ cursor: 'pointer' }} onClick={() => handleRemoveComplaint(index)} />
                        </div>
                      ))}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="1">
       <Accordion.Header onClick={() => handleToggle('1')}>
         <span className="custom-icon">
               {activeKey.includes('1') ? <FaMinus /> : <FaPlus />}
            </span>
            Examination</Accordion.Header>
         <Accordion.Body>
         <div className="examination-form position-relative p-3 border rounded">
              
                 <Form.Group controlId="formGroupExamination">
                   <Form.Label>Examination</Form.Label>
                   <Form.Control as="textarea" rows={3} placeholder="Enter examination details" />
                 </Form.Group>
               </div>
         </Accordion.Body>
       </Accordion.Item>
       <Accordion.Item eventKey="2">
                <Accordion.Header onClick={() => handleToggle('2')}>
                  <span className="custom-icon">
                    {activeKey.includes('2') ? <FaMinus /> : <FaPlus />}
                  </span>
                  Prescription
                </Accordion.Header>
                <Accordion.Body>
                  <div className="prescription-form position-relative p-3 border rounded">
                    <Form.Group controlId="formGroupMedicineName">
                      <Form.Label>Medicine Name *</Form.Label>
                      <Form.Control
                        type="text"
                        name="medicineName"
                        value={prescription.medicineName}
                        onChange={handlePrescriptionChange}
                        placeholder="Search or add medicine name"
                        
                      />
                    </Form.Group>

                    <Row className="mt-3">
                      <Col>
                        <Form.Group controlId="formGroupDosage">
                          <Form.Label>Dosage</Form.Label>
                          <Form.Control
                            as="select"
                            name="dosage"
                            value={prescription.dosage}
                            onChange={handlePrescriptionChange}
                           
                          >
                            <option value="">Select Dosage</option>
                            <option value="1-0-0">1 - 0 - 0</option>
                            <option value="1-1-0">1 - 1 - 0</option>
                            <option value="1-0-1">1 - 0 - 1</option>
                            <option value="0-0-1">0 - 0 - 1</option>
                            <option value="1-1-1">1 - 1 - 1</option>
                            <option value="0-1-0">0 - 1 - 0</option>
                          </Form.Control>
                          <Form.Text className="text-muted">
                            Ex: 1-1-1 (max value 999-999-999)
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formGroupMedicineType">
                          <Form.Label>Medicine Type</Form.Label>
                          <Form.Control
                            type="text"
                            name="medicineType"
                            value={prescription.medicineType}
                            onChange={handlePrescriptionChange}
                            placeholder="Enter medicine type"
                           
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <Form.Group controlId="formGroupDuration">
                          <Form.Label>Duration</Form.Label>
                          <Form.Control
                            type="text"
                            name="duration"
                            value={prescription.duration}
                            onChange={handlePrescriptionChange}
                            placeholder="Enter duration"
                           
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formGroupQuantity">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type="text"
                            name="quantity"
                            value={prescription.quantity}
                            onChange={handlePrescriptionChange}
                            placeholder="Enter quantity"
                           
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <Form.Group controlId="formGroupMeal">
                          <Form.Label>Meal</Form.Label>
                          <Form.Check
                            type="radio"
                            label="After"
                            name="meal"
                            value="After"
                            checked={prescription.meal === 'After'}
                            onChange={handlePrescriptionChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Before"
                            name="meal"
                            value="Before"
                            checked={prescription.meal === 'Before'}
                            onChange={handlePrescriptionChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formGroupNote">
                          <Form.Label>Note</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="note"
                            value={prescription.note}
                            onChange={handlePrescriptionChange}
                            rows={3}
                            placeholder="Enter additional notes"
                            
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      variant="primary"
                      className="mt-3 "
                      onClick={handleAddPrescription}
                    >
                      Save
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
       <Accordion.Item eventKey="3">         
        <Accordion.Header onClick={() => handleToggle('3')}>
         <span className="custom-icon">
               {activeKey.includes('3') ? <FaMinus /> : <FaPlus />}
             </span>
             Patient Report</Accordion.Header>
         <Accordion.Body>
         <div className="patient-report-form position-relative p-3 border rounded">
                
                 <Form.Group controlId="formGroupLabCity">
                   <Form.Label>Lab (City)</Form.Label>
                  <Form.Control type="text" placeholder="Enter lab city" />
                 </Form.Group>
                 <Form.Group controlId="formGroupReports">                   
                  <Form.Label>Reports</Form.Label>                   
                  <Form.Control type="text" placeholder="Search or add report" />
                 </Form.Group>
                 <Form.Group controlId="formGroupHeight">
                   <Form.Label>Height (in Feet inches)</Form.Label>
                   <Form.Control type="text" placeholder="Ex. 5.3" />                
                   </Form.Group>
                 <Form.Group controlId="formGroupWeight">
                   <Form.Label>Weight (Kg)</Form.Label>
                   <Form.Control type="text" placeholder="Ex. 40" />
                 </Form.Group>
                 <Form.Group controlId="formGroupBloodPressure">
                   <Form.Label>Blood Pressure (mmHg)</Form.Label>
                   <Form.Control type="text" placeholder="Enter blood pressure" />
                 </Form.Group>
               </div>
         </Accordion.Body>
       </Accordion.Item>
       <Accordion.Item eventKey="4">
         <Accordion.Header onClick={() => handleToggle('4')}>
         <span className="custom-icon">               
          {activeKey.includes('4') ? <FaMinus /> : <FaPlus />}
             </span>
             Case of referred</Accordion.Header>
         <Accordion.Body>
         <div className="referred-form position-relative p-3 border rounded">
               
                 <Form.Group controlId="formGroupDoctorName">
                   <Form.Label>Doctor Name</Form.Label>
                   <Form.Control type="text" placeholder="Enter doctor name" />
                </Form.Group>
                 <Form.Group controlId="formGroupClinicName">
                   <Form.Label>Clinic Name</Form.Label>
                   <Form.Control type="text" placeholder="Enter clinic name" />
                 </Form.Group>
               </div>
         </Accordion.Body>
       </Accordion.Item>

       <Accordion.Item eventKey="5">
         <Accordion.Header onClick={() => handleToggle('5')}>
         <span className="custom-icon">
               {activeKey.includes('5') ? <FaMinus /> : <FaPlus />}
             </span>
             Add Images</Accordion.Header>
         <Accordion.Body>
         <div className="images-form position-relative p-3 border rounded">
                               <Form.Group controlId="formGroupTreatmentImages">
                  <Form.Label>Add Treatment Images</Form.Label>
                   <Form.Control type="file" multiple />
                   <small className="text-muted">Upload photos or just drag and drop</small>
                 </Form.Group>
                 <Form.Group controlId="formGroupPrescriptionImages" className="mt-3">
                   <Form.Label>Add Prescription Images</Form.Label>
                   <Form.Control type="file" multiple />
                   <small className="text-muted">Upload photos or just drag and drop</small>
                 </Form.Group>
               </div>
         </Accordion.Body>
       </Accordion.Item>
            </Accordion>

            <hr />
            <div className="d-flex justify-content-between mt-3">
              <Button variant="primary" className="rounded-pill">Save Treatment</Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
