import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';

export default function AddPrescription() {
  const [medicineName, setMedicineName] = useState('');
  const [medicineType, setMedicineType] = useState('');

  const handleMedicineChange = (e) => {
    const name = e.target.value;
    setMedicineName(name);

    // Automatically set medicine type based on medicine name
    if (name === 'Crocin') {
      setMedicineType('Tablet');
    } else {
      setMedicineType('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Medicine Name:', medicineName);
    console.log('Medicine Type:', medicineType);
  };

  return (
    <Card className="shadow p-3">
      <Card.Title className="text-center">Add Prescription</Card.Title>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formMedicineName">
            <Form.Label>Medicine Name</Form.Label>
            <Form.Control
              type="text"
              value={medicineName}
              onChange={handleMedicineChange}
              placeholder="Enter medicine name"
              className="rounded-input"
            />
          </Form.Group>
          <Form.Group controlId="formMedicineType" className="mt-3">
            <Form.Label>Medicine Type</Form.Label>
            <Form.Control
              type="text"
              value={medicineType}
              readOnly
              placeholder="Medicine type"
              className="rounded-input"
            />
          </Form.Group>
          <Button type="submit" variant="primary" className="mt-3 rounded-pill">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
