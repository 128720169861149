import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';

export default function DoctorForm() {
  const [doctorDetails, setDoctorDetails] = useState({
    registrationNumber: '',
    doctorCategory: '',
    doctorName: '',
    specialization: '',
    phoneNumber: '',
    email: '',
    address: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDoctorDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to API)
    console.log('Doctor Details:', doctorDetails);
  };

  return (
    <Card className="shadow">
      <Card.Title className="text-center mt-3">Doctor Details</Card.Title>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupRegistrationNumber">
                <Form.Label>Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="registrationNumber"
                  value={doctorDetails.registrationNumber}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupDoctorCategory">
                <Form.Label>Doctor Category</Form.Label>
                <Form.Select
                  name="doctorCategory"
                  value={doctorDetails.doctorCategory}
                  onChange={handleChange}
                  required
                >
                  <option value="">Choose...</option>
                  <option value="1">Referred From</option>
                  <option value="2">Referred To</option>
                  <option value="3">Both</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupDoctorName">
                <Form.Label>Doctor Name</Form.Label>
                <Form.Control
                  type="text"
                  name="doctorName"
                  value={doctorDetails.doctorName}
                  onChange={handleChange}
                  placeholder="Enter doctor's name"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupSpecialization">
                <Form.Label>Specialization</Form.Label>
                <Form.Control
                  type="text"
                  name="specialization"
                  value={doctorDetails.specialization}
                  onChange={handleChange}
                  placeholder="Enter specialization"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phoneNumber"
                  value={doctorDetails.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={doctorDetails.email}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formGroupAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              name="address"
              value={doctorDetails.address}
              onChange={handleChange}
              placeholder="Enter address"
              rows={3}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Details
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
