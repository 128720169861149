import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import { LuPlusCircle } from "react-icons/lu";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Link } from 'react-router-dom';

export default function Treatment() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Updated dummy data to include complaints
  const treatmentData = [
    { patientName: 'John Doe', complaint: 'Headache', examination: 'General Checkup', visitDate: '2024-08-01', followUpDate: '2024-08-15', fees: '100', paid: '50' },
    { patientName: 'Jane Smith', complaint: 'Toothache', examination: 'Dental Checkup', visitDate: '2024-08-02', followUpDate: '2024-08-16', fees: '200', paid: '150' },
  ];

  return (
    <Container fluid>
      <Row className="mb-3 align-items-center">
        <Col md={8}>
          <h2>Treatment</h2>
        </Col>
        <Col md={4} className="text-end">
          <Button variant="primary" className="me-2" onClick={handleShowModal}>
            Quick Entry <LuPlusCircle size='20px'/>
          </Button>
          <Button variant="secondary" as={Link} to='addtreatment'>
            Add Treatment <LuPlusCircle size='20px'/>
          </Button>
        </Col>
      </Row>
      <Form>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Control type="text" placeholder="Search" />
          </Col>
          <Col md={4}>
            <Form.Control as="select">
              <option value="">Select Patient Name</option>
              <option value="Patient 1">Patient 1</option>
              <option value="Patient 2">Patient 2</option>
            </Form.Control>
          </Col>
          <Col md={4}>
            <Form.Control type="date" />
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Patient Complaint</th>
            <th>Examination</th>
            <th>Visit Date</th>
            <th>Follow Up Date</th>
            <th>Fees</th>
            <th>Paid</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {treatmentData.map((row, index) => (
            <tr key={index}>
              <td>{row.patientName}</td>
              <td>{row.complaint}</td>
              <td>{row.examination}</td>
              <td>{row.visitDate}</td>
              <td>{row.followUpDate}</td>
              <td>{row.fees}</td>
              <td>{row.paid}</td>
              <td>
                <Button variant="outline-primary" size="sm" className="me-2">
                  <AiFillEdit />
                </Button>
                <Button variant="outline-danger" size="sm">
                  <AiFillDelete />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Quick Entry */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Quick Entry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Patient Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Remark</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Complaints</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Examination</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Total Fees</Form.Label>
              <Form.Control type="number" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Paid Fees</Form.Label>
              <Form.Control type="number" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
