import React, { useState } from 'react';
import { Form, Button, Card, ListGroup } from 'react-bootstrap';


export default function ComplaintForm() {
  const [symptom, setSymptom] = useState('');
  const [symptomsList, setSymptomsList] = useState([]);

  const handleAddSymptom = (e) => {
    e.preventDefault();
    if (symptom.trim() !== '') {
      setSymptomsList([...symptomsList, symptom.trim()]);
      setSymptom('');
    }
  };

  return (
    <div>
    <Card className="shadow p-3">
      <Card.Title className="text-center">Add Complaint</Card.Title>
      <Card.Body>
        <Form onSubmit={handleAddSymptom}>
          <Form.Group controlId="formSymptom">
            <Form.Label>Symptom Name</Form.Label>
            <Form.Control
              type="text"
              value={symptom}
              onChange={(e) => setSymptom(e.target.value)}
              placeholder="Enter symptom"
              className="rounded-input"
            />
          </Form.Group>
          <Button type="submit" variant="primary" className="mt-3 rounded-pill">
            Add Symptom
          </Button>
        </Form>

        <ListGroup className="mt-4">
          {symptomsList.map((symptom, index) => (
            <ListGroup.Item key={index}>{symptom}</ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
      
    </div>
  );
}
