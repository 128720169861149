import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const ProfessionalDetail = () => {
  const [formValues, setFormValues] = useState({
    educationQualification: '',
    medicalLicenseNumber: '',
    specialization: '',
    yearsOfExperience: '',
    previousEmployers: '',
    certifications: '',
    professionalAssociations: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Professional Details Form Submitted', formValues);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom
              sx={{
                textAlign: 'center',  
                color: 'blue',        
              }}
        >
          Professional Details
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Education Qualification"
            name="educationQualification"
            value={formValues.educationQualification}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Medical License Number (if applicable)"
            name="medicalLicenseNumber"
            value={formValues.medicalLicenseNumber}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Specialization/Area of Expertise"
            name="specialization"
            value={formValues.specialization}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Years of Experience"
            name="yearsOfExperience"
            value={formValues.yearsOfExperience}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Previous Employer(s)"
            name="previousEmployers"
            value={formValues.previousEmployers}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Certifications & Training"
            name="certifications"
            value={formValues.certifications}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Professional Associations"
            name="professionalAssociations"
            value={formValues.professionalAssociations}
            onChange={handleChange}
            margin="normal"
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default ProfessionalDetail;
