import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const EmergencyContact = () => {
  const [formValues, setFormValues] = useState({
    emergencyContactName: '',
    relationship: '',
    emergencyContactNumber: '',
    emergencyContactAddress: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Emergency Contact Form Submitted', formValues);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom
              sx={{
                textAlign: 'center',  
                color: 'blue',        
              }}
        >
          Emergency Contact Information
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Emergency Contact Name"
            name="emergencyContactName"
            value={formValues.emergencyContactName}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Relationship"
            name="relationship"
            value={formValues.relationship}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Emergency Contact Number"
            name="emergencyContactNumber"
            value={formValues.emergencyContactNumber}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Emergency Contact Address"
            name="emergencyContactAddress"
            value={formValues.emergencyContactAddress}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default EmergencyContact;
