import './navigation.css';
import './Theme.css';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { IoCallOutline } from 'react-icons/io5';
import { CiMail } from 'react-icons/ci';
import { FaWhatsapp } from 'react-icons/fa';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
// import NavDropdown from 'react-bootstrap/NavDropdown';

const Navigation = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = ""; 
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div>
     <Row className="header-icons">
      <Col xs={12}>
        <div className="header d-flex justify-content-center align-items-center t_bg1">
       

          <Nav className="ms-auto">
            <Nav.Link className="t_text1 icon-link" href="tel:+919890089721" title="Contact no.">
              <IoCallOutline className="icon" />
              <span className="ms-1 d-none d-md-inline">+91-9890089721</span>
            </Nav.Link>
            <Nav.Link className="t_text1 icon-link" href="mailto:info@loginrich.edu.in" title="Email">
              <CiMail className="icon" />
              <span className="ms-1 d-none d-md-inline">info@loginrich.edu.in</span>
            </Nav.Link>
            <Nav.Link className="t_text1 icon-link" href="#" title="Whatsapp" onClick={handleWhatsAppClick}>
              <FaWhatsapp className="icon" />
              <span className="ms-1 d-none d-md-inline">Whatsapp</span>
            </Nav.Link>
          </Nav>
        </div>
      </Col>
    </Row>

      <Row>
        <Col xs={12}>
          <Navbar collapseOnSelect expand="lg" className="bg-body shadow">
          <Navbar.Brand href="#home" className='ms-4'>
                <img
                  alt=""
                  src="../image/logo.png"
                  width="50"
               
                />
                   HMS      
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto me-auto">
              <Nav.Link className="navbarex" href="#" title="aboutus" as={Link} to='/'>
                 Home
                </Nav.Link>
                <Nav.Link className="navbarex" href="#" title="aboutus">
                 About Us
                </Nav.Link>
                <Nav.Link className="navbarex" href="#" title="aboutus">
                 Doctors
                </Nav.Link>
                <Nav.Link className="navbarex" href="#" title="aboutus">
                 New
                </Nav.Link>
                <Nav.Link className="navbarex" href="#" title="aboutus">
                 Contact Us
                </Nav.Link>

                {/* start */}
                </Nav>

                {/* end */}
                <div className='ms-auto me-2'>
                <Nav.Link href="#" >
                  <Button className='paybtn' variant="me-2"as={Link} to='/login'>Login</Button>
                </Nav.Link>
                </div>
    
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </div>
  );
};

export default Navigation;
