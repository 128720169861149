import React from 'react';
 import './dashboardform.css'; // Assuming you have a CSS file for styling

const DashboardForm = () => {
    // Mock Data, replace with actual API call data
    const totalAppointments = 20;
    const totalPatients = 150;
    const totalDoctors = 10;
    const todayAppointments = [
        { id: 1, name: 'John Doe', time: '10:00 AM', status: 'Confirmed' },
        { id: 2, name: 'Jane Smith', time: '11:00 AM', status: 'Requested' },
        { id: 3, name: 'Sam Williams', time: '1:00 PM', status: 'Started' },
    ];

    return (
        <div className="dashboardcard">
        
            <div className="stats">
                <div className="card-dashboard">
                    <h2>{totalAppointments}</h2>
                    <p>Total Appointments</p>
                </div>
                <div className="card-dashboard">
                    <h2>{totalPatients}</h2>
                    <p>Total Patients</p>
                </div>
                <div className="card-dashboard">
                    <h2>{totalDoctors}</h2>
                    <p>Total Doctors</p>
                </div>
            </div>
            <div className="today-appointments">
                <h2>Today's Appointments</h2>
                <ul>
                    {todayAppointments.map(appointment => (
                        <li key={appointment.id}>
                            <div className="appointment-info">
                                <p><strong>Patient:</strong> {appointment.name}</p>
                                <p><strong>Time:</strong> {appointment.time}</p>
                                <p><strong>Status:</strong> {appointment.status}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DashboardForm;
