import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const BankDetails = () => {
  const [formValues, setFormValues] = useState({
    bankName: '',
    accountNumber: '',
    ifscCode: '',
    branchAddress: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Bank Details Form Submitted', formValues);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <CardContent>
      <Typography
              variant="h6"
              gutterBottom
              sx={{
                textAlign: 'center',  
                color: 'blue',        
              }}
        >
            Bank Details
      </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Bank Name"
            name="bankName"
            value={formValues.bankName}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Account Number"
            name="accountNumber"
            value={formValues.accountNumber}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="IFSC Code"
            name="ifscCode"
            value={formValues.ifscCode}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Branch Address"
            name="branchAddress"
            value={formValues.branchAddress}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default BankDetails;
