import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ComplaintForm from './setting/ComplaintForm';
import AddExamination from './setting/AddExamination';
import AddPrescription from './setting/AddPrescription';
import { FaPlus, FaMinus, FaPrescriptionBottleAlt } from "react-icons/fa";
import { BsFillFileTextFill, BsFillFileEarmarkTextFill } from "react-icons/bs";
import CertificateForm from './setting/CertificateForm';
import { PiCertificateFill } from "react-icons/pi";

export default function Setting() {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    if (activeKey === key) {
      setActiveKey(null); 
    } else {
      setActiveKey(key); 
    }
  };

  const renderIcon = (key) => {
    return activeKey === key ? <FaMinus /> : <FaPlus />;
  };

  return (
    <div>
      <Accordion activeKey={activeKey}>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={() => handleToggle('0')}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <span>
                <BsFillFileEarmarkTextFill size='20px' style={{ marginRight: '10px' }} />
                Add Complaint
              </span>
              <span>{renderIcon('0')}</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ComplaintForm />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={() => handleToggle('1')}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <span>
                <BsFillFileTextFill size='20px' style={{ marginRight: '10px' }} />
                Add Examination
              </span>
              <span>{renderIcon('1')}</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <AddExamination />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header onClick={() => handleToggle('2')}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <span>
                <FaPrescriptionBottleAlt size='20px' style={{ marginRight: '10px' }} />
                Add Prescription
              </span>
              <span>{renderIcon('2')}</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <AddPrescription />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header onClick={() => handleToggle('3')}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <span>
              <PiCertificateFill size='20px' style={{ marginRight: '10px' }} />
                Certificate
              </span>
              <span>{renderIcon('3')}</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <CertificateForm></CertificateForm>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
